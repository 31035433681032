function App() {
  return (
    <div className="App">
      <header className="header__wrapper">
        <h1>HARLEY WEIR</h1>
      </header>
      <main className="main__wrapper">
        <img src="./Tractor.jpg" alt="Under Construction" />
      </main>
      <footer className="footer__wrapper">
        <img src="./Tractor.svg" alt="Under Construction"  className="footer__image"/>
        <h1>* Website Under Construction *</h1>
      </footer>
    </div>
  );
}

export default App;
